import React from 'react';
import { Link } from 'react-router-dom';
import Services from '../../api/Services';
import Bg from '../../images/services/service-bg.jpg'




const ServiceSection2 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className="services-section pt-250 rpt-100 pb-70" style={{ backgroundImage: `url(${Bg})` }}>
            <div className="container">
                <div className="row">
                    {Services.slice(3,12).map((service, Sitem) => (
                        <div className="col-lg-4 col-md-6 col-12" key={Sitem}>
                            <div className="service-item">
                                <span className="number">0{service.Id}</span>
                                <h3>{service.sTitle}</h3>
                                <p>{service.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}


export default ServiceSection2;