import React, { useState } from 'react';
import fImg from '../../images/faq/faq-right-bg.png'
import fImg1 from '../../images/services/img1.png'
import fImg2 from '../../images/services/img2.png'

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';



const FaqSectionS2 = (props) => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (

        <section className="faq-section mt-150">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 pr-125 rpr-15">
                        <div class="service-page-images rmb-150">
                            <img src={fImg1} alt="Services"/>
                            <img src={fImg2} alt="Service"/>
                        </div>
                    </div>
                    <div className="col-lg-6 pl-0">
                        <div className="faq-accordion ras-content pt-95 pb-110 rpb-0 rmb-100">
                            <div className="section-title mb-35">
                                <h6>Best Architecture</h6>
                                <h2>Architecture did  expect
                                    <span className="thin">strategist</span>
                                </h2>
                            </div>
                            <div id="accordion">
                                <Accordion open={open} toggle={toggle}>
                                    <AccordionItem className='card'>
                                        <AccordionHeader targetId="1" className='card-header'>Services</AccordionHeader>
                                        <AccordionBody accordionId="1">
                                        We offer a comprehensive range of interior design services tailored to suit various project types and sizes. Whether it's a residential, commercial, or hospitality project, we bring creativity, innovation, and attention to detail to every stage of the design process
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem className='card'>
                                        <AccordionHeader targetId="2" className='card-header'>Concept Development</AccordionHeader>
                                        <AccordionBody accordionId="2">
                                        We work closely with our clients to develop a cohesive design concept that aligns with their vision and requirements. Through mood boards, sketches, and 3D visualizations, we bring ideas to life and ensure a shared understanding of the final design direction.
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem className='card'>
                                        <AccordionHeader targetId="3" className='card-header'>Space Planning</AccordionHeader>
                                        <AccordionBody accordionId="3">
                                        Our team excels in optimizing space utilization and flow. We meticulously plan and arrange layouts, considering functionality, traffic patterns, and ergonomic principles, to create harmonious and efficient spaces that cater to our clients' specific needs.
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem className='card'>
                                        <AccordionHeader targetId="4" className='card-header'>Material Selection</AccordionHeader>
                                        <AccordionBody accordionId="4">
                                        We have an extensive knowledge of materials, finishes, and furnishings. From flooring and wall treatments to furniture and lighting, we guide our clients in selecting the most suitable options that align with their design preferences, budget, and sustainability goals.
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem className='card'>
                                        <AccordionHeader targetId="5" className='card-header'>Project Management</AccordionHeader>
                                        <AccordionBody accordionId="5">
                                        Our dedicated project management team ensures that the design vision is seamlessly executed from start to finish. We handle coordination with contractors, procurement, and oversee the construction process to ensure quality, timeliness, and budget adherence.
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem className='card'>
                                        <AccordionHeader targetId="6" className='card-header'>Client Satisfaction</AccordionHeader>
                                        <AccordionBody accordionId="6">
                                        Client satisfaction is at the core of our business. We prioritize open communication, transparency, and a collaborative approach throughout the project journey. Our goal is to not only meet but exceed our clients' expectations by delivering exceptional design solutions that transform their spaces into beautiful, functional realities.<br/><br/>We invite you to explore our portfolio to witness the transformative power of our designs. Contact us today to discuss your upcoming project and let us bring your vision to life
                                        </AccordionBody>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                        <div className="faq-right-bg">
                            <img src={fImg} alt="FAQ" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default FaqSectionS2;