import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import Navbar from '../../components/Navbar/Navbar'
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import About2 from '../../components/about2/about2';
// import ServiceSection from '../../components/ServiceSection/ServiceSection';
import FunFact from '../../components/FunFact';
// import TeamSection from '../../components/TeamSection/TeamSection';
const Services = [
    {
        Id: '1',
        sTitle: 'Collaborative Approach',
        description:'We believe in the power of collaboration. Our designers work closely with you, listening to your needs and preferences, to create a space that truly reflects your personality and lifestyle.',
    },
    {
        Id: '2',
        sTitle: 'Attention to Detail',
        description:' Every detail matters. We meticulously consider every aspect of the design, from color schemes and materials to lighting and accessories, ensuring a cohesive and visually stunning result.',
    },
    {
        Id: '3',
        sTitle: 'Quality and Professionalism',
        description:'We take pride in delivering exceptional quality and service. Our team consists of experienced professionals who are dedicated to surpassing your expectations and creating interiors that stand the test of time.',
    },
    {
        Id: '4',
        sTitle: 'Client Satisfaction',
        description:'Your satisfaction is our ultimate goal. We strive to create spaces that not only meet but exceed your expectations. Our commitment to excellence has earned us a reputation for client satisfaction and referrals.',
    }
]
const AboutPage = () => {
    return (
        <Fragment>
            <Navbar />
            <PageTitle pageTitle={'About Us'} pagesub={'About'} />
            {/* <ServiceSection ptClass={'pt-100'}/> */}
            <About2 />
            <FunFact fClass={'style-two pt-165 pb-125 mb-0'}/>
            {/* <TeamSection/> */}
            
            <div className='container mt-4'>
                <div className='row'>
            {Services.slice(0,6).map((service, Sitem) => (
                        <div className="col-lg-4 col-md-6 col-12" key={Sitem}>
                            <div className="service-item" style={{"border":"1px solid #ebe9e9"}}>
                                <span className="number">0{service.Id}</span>
                                <h3>{service.sTitle}</h3>
                                <p>{service.description}</p>
                            </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default AboutPage;
