import React, { useState } from 'react';
import fImg from '../../images/faq/faq-right-bg.png'

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';



const FaqSection = (props) => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (

        <section className="faq-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 pr-125 rpr-15">
                        <div className="faq-left-image"></div>
                    </div>
                    <div className="col-lg-6 pl-0">
                        <div className="faq-accordion ras-content pt-95 pb-110 rpb-0 rmb-100">
                            <div className="section-title mb-35">
                                <h6>Best Interior</h6>

                                <h2>Make Everything Around you,
                                    <span className="thin"> About You</span>
                                </h2>
                            </div>
                            <div id="accordion">
                                <Accordion open={open} toggle={toggle}>
                                    <AccordionItem className='card'>
                                        <AccordionHeader targetId="1" className='card-header'>At ARTFUL SPACE</AccordionHeader>
                                        <AccordionBody accordionId="1">
                                        we believe in the power of design to enhance lives and create With our passion for creativity and attention to detail, we strive to bring your vision to life and make your dream home a reality.
                                        <br/><br/>
                                        Design, Production & Installation <br/>By One Company <br/>CUSTOM-MADE INTERIOR SOLUTION
                                        </AccordionBody>
                                    </AccordionItem>
                                    
                                </Accordion>
                            </div>
                        </div>
                        <div className="faq-right-bg">
                            <img src={fImg} alt="FAQ" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default FaqSection;