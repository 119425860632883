import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Services from '../../api/Services';
// import Contact from './contact';
import Footer from '../../components/footer/Footer';
import './style.scss'

const ServiceSinglePage = (props) => {

    const { slug } = useParams()

    const serviceDetails = Services.find(item => item.slug === slug)

    return (
        <Fragment>
            <Navbar hclass={'header-style-2'} />
            <PageTitle pageTitle={serviceDetails.sTitle} pagesub={'Service'} />
            <div className="service-single-area section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-12">
                            <div className="service-single-wrap">
                                <div className="service-single-item">
                                    <div className="service-single-title">
                                        <h3>{serviceDetails.sTitle}</h3>
                                    </div>
                                    <div className="service-single-main-img">
                                        <img src={serviceDetails.sSimg} alt="" />
                                    </div>
                                </div>
                                <div className="service-single-item list-widget">
                                    <div className="service-single-title">
                                        <h3>MATERIALS/QUALITY</h3>
                                    </div>
                                    
                                    <ul>
                                        <li>PLYWOOD</li>
                                        <p>IS 303, NABL accredited laboratory certified. Constructed from 11 layers of Eucalyptus and Hardwood veneers, rendering it robust and resistant to termites.
                                        Utilization of E1 adhesive guarantees reduced emissions, ensuring the safety of your home and family.
                                        Offered in Moisture-Resistant (MR) and Boiling-Water Resistant (BWR) variants.
                                        </p>
                                        <li>HDHMR</li>
                                        <p>IS 15380, NABL-accredited laboratory certified.
                                        Enjoy all the benefits of medium-density fiberboard (MDF) with enhanced density and resistance to moisture. It is particularly well-suited for areas in your home that are prone to moisture.
                                        </p>
                                        <li>MDF</li>
                                        <p>Certified by IS 12406, this laboratory holds NABL accreditation. It is made from compressed wood fibers with resin under controlled heat and pressure, resulting in a sleek surface devoid of knots and grain patterns. It is an excellent choice for achieving flawless finishes.
                                        It offers exceptional screw holding capacity and exhibits remarkable durability and resistance to wear and tear.
                                        </p>
                                        
                                    </ul>
                                </div>
                                <div className="service-single-item">
                                    <div className="service-single-title">
                                        <h3>Our approach</h3>
                                    </div>
                                    <p>We believe that every space has its unique character and potential. Our approach to interior design is rooted in understanding our clients' vision, needs, and preferences. By combining our expertise with a collaborative mindset, we create personalized design concepts that reflect the essence of our clients' style and enhance the functionality of their spaces.</p>
                                </div>
                                <div className="service-single-item list-widget">
                                    <div className="service-single-title">
                                        <h3>Our Work Process</h3>
                                    </div>
                                    <ul>
                                        <li>Consult with our experts & get an estimate</li>
                                        <li>Detailed drawing & approval</li>
                                        <li>Automated Production @ factory</li>
                                        <li>Material Delivery & Implementation</li>
                                        <li>On time project handover</li>
                                    </ul>
                                </div>
                                <div className="service-single-item">
                                    <div className="service-single-title">
                                        <h3>Related Service</h3>
                                    </div>
                                    <div className="service-area">
                                        <div className="row align-items-center">
                                            {Services.slice(0,3).map((service, srv) => (
                                                <div className="col-lg-4 col-md-6 col-12" key={srv}>
                                                    <div className="service-item">
                                                        <i><img src={service.sImg} alt="" /></i>
                                                        <h2>{service.sTitle}</h2>
                                                        <p>We are expert in all type of interiors.</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="service-single-item">
                                    <div className="service-contact-area">
                                        <div className="contact-title">
                                            <h2>Any question? Let's discuss</h2>
                                            <p>Get in touch with us to see how we can help you with your project</p>
                                        </div>
                                        <div className="contact-form-area">
                                            <Contact/>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
            <Scrollbar />
        </Fragment>
    )
};
export default ServiceSinglePage;
