import React from 'react';
import ContactForm from '../ContactFrom'
import sIcon1 from '../../images/contact/icon1.png'
import sIcon2 from '../../images/contact/icon2.png'
import sIcon3 from '../../images/contact/icon3.png'


const Contactpage = () => {

    return (
        <div>
            <div class="contact-map" id="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d497511.23105453793!2d79.8793273821512!3d13.047985948738418!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265ea4f7d3361%3A0x6e61a70b6863d433!2sChennai%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1708363899450!5m2!1sen!2sin"></iframe>
            </div>
            <div className="contact-info text-center mb-110">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="info-item">
                            <img src={sIcon1} alt="Icon"/>
                                <p>Chennai</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="info-item">
                            <img src={sIcon2} alt="Icon"/>
                                <p>arftfulspaceinterior@gmail.com <br/>8072305218</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="info-item">
                            <img src={sIcon3} alt="Icon"/>
                                <p>Web Address: <br/>artfulspace.in</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactForm/>
        </div>
    )

}

export default Contactpage;
