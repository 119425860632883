import React from 'react'
// import abimg from '../../images/wp/4.jpg'


const About2 = (props) => {

    return (
        <section className="about-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="about-image mr-50 rmr-0">
                            <div className="experience-year">
                                <span className="number">15</span>
                                <h4>Years of Experience</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="wp-content about-content mb-10 mr-50 pt-100 rmb-50" style={{"maxWidth":"100%"}}>
                            <div className="section-title mb-10">
                                <h6>ABOUT US</h6>
                                
                                <p>At ARTFUL SPACE, we are passionate about transforming spaces into stunning, functional, and inspiring environments. With years of experience in the industry, our dedicated team of talented designers and professionals is committed to delivering exceptional design solutions that exceed our clients' expectations.<br/><br/>Take the first step towards transforming your space into something extraordinary. Contact us today to schedule a consultation and let us bring your interior design dreams to life!</p>
                            </div>
                            {/* <img src={abimg} alt="" /> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About2;