import simg from '../images/services/icon1.png'
import simg2 from '../images/services/icon2.png'
import simg3 from '../images/services/icon3.png'
import simg4 from '../images/services/icon4.png'
import simg5 from '../images/services/icon5.png'
import simg6 from '../images/services/icon6.png'

import sSimg1 from '../images/wp/banner_port.jpeg'
import sSimg2 from '../images/wp/banner_port.jpeg'
import sSimg3 from '../images/wp/banner_port.jpeg'
import sSimg4 from '../images/service-single/img-1.jpg'
import sSimg5 from '../images/service-single/img-2.jpg'
import sSimg6 from '../images/service-single/img-3.jpg'

import sSingleimg1 from '../images/wp/banner_port.jpeg'
import sSingleimg2 from '../images/wp/banner_port.jpeg'

const Services = [
    {
        Id: '1',
        sImg:simg,
        sSimg:sSimg1,
        sTitle: 'Residential design',
        slug: 'Residential-design',
        description:'',
        des2:'Elementum aliquam byuerirra sagittis duis non quis.',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '2',
        sImg:simg2,
        sSimg:sSimg2,
        sTitle: 'Commercial design',
        slug: 'Commercial-design',
        description:'',
        des2:'Elementum aliquam byuerirra sagittis duis non quis.',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        sImg:simg3,
        sSimg:sSimg3,
        sTitle: 'Renovations',
        slug: 'Renovations',
        description:'',
        des2:'Elementum aliquam byuerirra sagittis duis non quis.',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '1',
        sTitle: 'Kitchens',
        description:'Noimy donecing and vivamus vitae commodo. Ut purus ornare laoreet ultrices are sitac',
     
    },
    {
        Id: '2',
        sTitle: 'Wardrobes',
        description:'Noimy donecing and vivamus vitae commodo. Ut purus ornare laoreet ultrices are sitac',
        
    },
    {
        Id: '3',
        sTitle: 'TV Units',
        description:'Noimy donecing and vivamus vitae commodo. Ut purus ornare laoreet ultrices are sitac',
    },
    {
        Id: '4',
        sTitle: 'Dresser',
        description:'Noimy donecing and vivamus vitae commodo. Ut purus ornare laoreet ultrices are sitac',
     
    },
    {
        Id: '5',
        sTitle: 'Bathroom Vanity',
        description:'Noimy donecing and vivamus vitae commodo. Ut purus ornare laoreet ultrices are sitac',
        
    },
    {
        Id: '6',
        sTitle: 'Dining',
        description:'Noimy donecing and vivamus vitae commodo. Ut purus ornare laoreet ultrices are sitac',
    },
    {
        Id: '7',
        sTitle: 'Bedrooms',
        description:'Noimy donecing and vivamus vitae commodo. Ut purus ornare laoreet ultrices are sitac',
     
    },
    {
        Id: '8',
        sTitle: 'Partition',
        description:'Noimy donecing and vivamus vitae commodo. Ut purus ornare laoreet ultrices are sitac',
        
    },
    {
        Id: '9',
        sTitle: 'Kids Room',
        description:'Noimy donecing and vivamus vitae commodo. Ut purus ornare laoreet ultrices are sitac',
    },
]
export default Services;